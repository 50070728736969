import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  { path: '/', redirect: '/quotes' },
  {
    path: '/auth/login',
    name: 'Login',
    component: () => import('../views/auth/NotAuthenticated.vue'),
    meta: {
      layout: 'blank'
    },
  },
  {
    path: '/auth/authenticate/:token',
    name: 'Authenticate',
    component: () => import('../views/auth/Authenticate.vue'),
    meta: {
      layout: 'blank'
    },
  },
  {
    path: '/auth/logout',
    name: 'Logout',
    component: () => import('../views/auth/Logout.vue'),
    meta: {
      layout: 'blank'
    },
  },
  {
    path: '/auth/pipedrive',
    name: 'Pipedrive',
    component: () => import('../views/auth/Pipedrive.vue'),
    meta: {
      layout: 'blank'
    },
  },
  {
    path: '/auth/pipedrive/callback',
    name: 'PipedriveCallback',
    component: () => import('../views/auth/PipedriveCallback.vue'),
    meta: {
      layout: 'blank'
    },
  },

  //----------------------------------------------------
  // QUOTES
  //----------------------------------------------------

  {
    path: '/quotes/page/:page?',
    alias: '/quotes',
    name: 'Quotes',
    component: () => import(/* webpackChunkName: "Quotes" */ '../views/quotes/Quotes.vue'),
    meta: {
      breadcrumb: [
        {
          title: 'Salg',
          name: 'Quotes',
          isParam: false,
        },
      ],
      auth: {
        roles: ['ROLE_ADMIN', 'ROLE_MODERATOR', 'ROLE_CONSULTANT']
      }
    }
  },
  // {
  //   path: '/quotes/bin/page/:page?',
  //   alias: '/quotes/bin',
  //   name: 'QuotesBin',
  //   meta: {
  //     breadcrumb: [
  //       {
  //         title: 'Quotes bin',
  //         name: 'quotes',
  //         isParam: false,
  //       }
  //     ],
  //     auth: {
  //       roles: ['ROLE_ADMIN', 'ROLE_MODERATOR']
  //     },
  //   },
  //   component: () => import(/* webpackChunkName: "QuotesBin" */ '../views/quotes/Quotes.vue')
  // },
  {
    path: '/quotes/new',
    name: 'newQuote',
    component: () => import(/* webpackChunkName: "Quote" */ '../views/quotes/Quote.vue'),
    meta: {
      breadcrumb: [
        {
          title: 'Salg',
          name: 'Quotes',
          isParam: false,
        }
      ],
      auth: {
        roles: ['ROLE_ADMIN', 'ROLE_MODERATOR', 'ROLE_CONSULTANT']
      }
    }
  },
  {
    path: '/quotes/edit/:id',
    name: 'QuoteEdit',
    component: () => import(/* webpackChunkName: "Quote" */ '../views/quotes/Quote.vue'),
    meta: {
      breadcrumb: [
        {
          title: 'Salg',
          name: 'Quotes',
          isParam: false,
        }
      ],
      auth: {
        roles: ['ROLE_ADMIN', 'ROLE_MODERATOR', 'ROLE_CONSULTANT']
      }
    }
  },

  //----------------------------------------------------
  // ADMIN - PRODUCTS
  //----------------------------------------------------

  {
    path: '/products/page/:page?',
    alias: '/products',
    name: 'Products',
    meta: {
      breadcrumb: [
        {
          title: 'Products',
          name: 'products',
          isParam: false,
        }
      ],
      auth: {
        roles: ['ROLE_ADMIN']
      }
    },
    component: () => import(/* webpackChunkName: "products" */ '../views/products/Products.vue')
  },

  {
    path: '/products/bin/page/:page?',
    alias: '/products/bin',
    name: 'ProductsBin',
    meta: {
      breadcrumb: [
        {
          title: 'Products bin',
          name: 'products',
          isParam: false,
        }
      ],
      auth: {
        roles: ['ROLE_ADMIN']
      },
    },
    component: () => import(/* webpackChunkName: "productsBin" */ '../views/products/Products.vue')
  },

  {
    path: '/products/edit/:id',
    name: 'ProductEdit',
    component: () => import(/* webpackChunkName: "product" */ '../views/products/Product.vue'),
    meta: {
      breadcrumb: [
        {
          title: 'Produkt',
          name: 'Products',
          isParam: false,
        }
      ],
      auth: {
        roles: ['ROLE_ADMIN']
      }
    },
  },

  {
    path: '/products/new',
    name: 'NewProduct',
    component: () => import(/* webpackChunkName: "product" */ '../views/products/Product.vue'),
    meta: {
      breadcrumb: [
        {
          title: 'Produkt',
          name: 'Products',
          isParam: false,
        }
      ],
      auth: {
        roles: ['ROLE_ADMIN']
      }
    },
  },

  {
    path: '/products/categories/',
    name: 'ProductCategpries',
    component: () => import(/* webpackChunkName: "productCategories" */ '../views/products/Categories.vue'),
    meta: {
      breadcrumb: [
        {
          title: 'Produkt Kategori',
          name: 'ProductCategories',
          isParam: false,
        }
      ],
      auth: {
        roles: ['ROLE_ADMIN']
      }
    },
  },

  //----------------------------------------------------
  // ADMIN - TEMPLATES
  //----------------------------------------------------

  {
    path: '/templates/page/:page?',
    alias: '/templates',
    name: 'Templates',
    meta: {
      breadcrumb: [
        {
          title: 'Templates',
          name: 'templates',
          isParam: false,
        }
      ],
      auth: {
        roles: ['ROLE_ADMIN']
      }
    },
    component: () => import( /* webpackChunkName: "templates" */'../views/templates/Templates.vue')
  },
  {
    path: '/templates/bin/page/:page?',
    alias: '/templates/bin',
    name: 'TemplatesBin',
    meta: {
      breadcrumb: [
        {
          title: 'Templates bin',
          name: 'templates',
          isParam: false,
        }
      ],
      auth: {
        roles: ['ROLE_ADMIN']
      },
    },
    component: () => import(/* webpackChunkName: "templatesBin" */ '../views/templates/Templates.vue')
  },

  {
    path: '/templates/edit/:id',
    name: 'TemplateEdit',
    component: () => import(/* webpackChunkName: "templatesEdit" */ '../views/templates/Template.vue'),
    meta: {
      breadcrumb: [
        {
          title: 'Skabelon',
          name: 'Templates',
          isParam: false,
        }
      ],
      auth: {
        roles: ['ROLE_ADMIN']
      }
    },
  },

  {
    path: '/templates/new',
    name: 'NewTemplate',
    component: () => import(/* webpackChunkName: "templatesNew" */ '../views/templates/Template.vue'),
    meta: {
      breadcrumb: [
        {
          title: 'Template',
          name: 'Templates',
          isParam: false,
        }
      ],
      auth: {
        roles: ['ROLE_ADMIN']
      }
    },
  },

  //----------------------------------------------------
  // CONTRACTORS
  //----------------------------------------------------

  {
    path: '/contractors/page/:page?',
    alias: '/contractors',
    name: 'Contractors',
    meta: {
      breadcrumb: [
        {
          title: 'Håndværkere',
          name: 'contractors',
          isParam: false,
        }
      ],
      auth: {
        roles: ['ROLE_ADMIN', 'ROLE_MODERATOR', 'ROLE_CONSULTANT']
      }
    },
    component: () => import(/* webpackChunkName: "contractors" */ '../views/contractors/Contractors.vue')
  },
  {
    path: '/contractor/:id',
    name: 'contractor',
    meta: {
      breadcrumb: [
        {
          title: 'Håndværker',
          name: 'contractor',
          isParam: false,
        }
      ],
      auth: {
        roles: ['ROLE_ADMIN', 'ROLE_MODERATOR', 'ROLE_CONSULTANT']
      }
    },
    component: () => import(/* webpackChunkName: "contractor" */ '../views/contractors/Contractor.vue')
  },

  //----------------------------------------------------
  // OFFERS
  //----------------------------------------------------
  {
    path: '/tilbud/:uuid?',
    name: 'Tilbud',
    component: () => import( '../views/offers/Offer.vue'),
    meta: {
      layout: 'blank',
      breadcrumb: [
        {
          title: 'Tilbud',
          name: 'tilbud',
          isParam: false,
        },
      ]
    },
  },

  {
    path: '/preview/:uuid?',
    name: 'Preview',
    component: () => import( '../views/offers/Preview.vue'),
    meta: {
      layout: 'blank',
      breadcrumb: [
        {
          title: 'Tilbud',
          name: 'tilbud',
          isParam: false,
        },
      ]
    },
  },

  {
    path: '/print-internal/:id?',
    name: 'printInternal',
    component: () => import( '../views/offers/PrintInternal.vue'),
    meta: {
      layout: 'blank',
      breadcrumb: [
        {
          title: 'Print intern',
          name: 'printinternal',
          isParam: false,
        },
      ],
      auth: {
        roles: ['ROLE_ADMIN', 'ROLE_MODERATOR', 'ROLE_CONSULTANT']
      }
    },
  },


  {
    path: '/toolbox',
    name: 'toolbox',
    component: () => import( '../views/toolbox/ToolBox.vue'),
    meta: {
      breadcrumb: [
        {
          title: 'Værktøjskasse',
          name: 'vaerktøjskasse',
          isParam: false,
        },
      ],
      auth: {
        roles: ['ROLE_ADMIN', 'ROLE_MODERATOR', 'ROLE_CONSULTANT']
      }
    },
  },



  //----------------------------------------------------
  // ADMIN - ACCOUNTING
  //----------------------------------------------------

  {
    path: '/accounting/page/:page?',
    alias: '/accounting',
    name: 'AcceptedQuotes',
    meta: {
      auth: {
        roles: ['ROLE_MODERATOR', 'ROLE_ADMIN']
      }
    },
    component: () => import( /* webpackChunkName: "AcceptedQuotes" */'../views/accounting/AcceptedQuotes.vue')
  },

  {
    path: '/reminders/page/:page?',
    alias: '/reminders',
    name: 'ReminderQuotes',
    meta: {
      auth: {
        roles: ['ROLE_MODERATOR', 'ROLE_ADMIN']
      }
    },
    component: () => import( /* webpackChunkName: "ReminderQuotes" */'../views/accounting/ReminderQuotes.vue')
  },

  {
    path: '/report/page/:page?',
    alias: '/report',
    name: 'report',
    meta: {
      auth: {
        roles: ['ROLE_MODERATOR', 'ROLE_ADMIN'],
        attributes: ['isManager'],
      }
    },
    component: () => import( /* webpackChunkName: "Report" */'../views/accounting/Report.vue')
  },

  //----------------------------------------------------

  // 404 -- Needs to be last entry.
  { path: "/:catchAll(.*)", component: () => import(/* webpackChunkName: "fourofour" */ '../views/Fourofour.vue'), }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
